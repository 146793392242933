import React from 'react';
import './AgencyPage.css'; // CSS file for the page
import video from '../assets/cpt-video-layer-2.webp'
import { Link } from 'react-router-dom';
const AgencyPage = () => {
    return (
        <div className="agency-page">
            {/* Section 1: Why you should work with our agency */}
            <section className="why-agency">
            <div className="full">
                <div className="why-content">
                    <h2>Why you should work with our agency?</h2>
                    <Link to ='/contact'><button className="get-started-btn">Get started</button></Link>
                </div>
                <div className="features">
                    <div className="feature-item">
                        <i className="icon-placeholder" />
                        <h3>Customer Satisfaction</h3>
                        <p>Our priority is to ensure the highest level of customer satisfaction.</p>
                    </div>
                    <div className="feature-item">
                        <i className="icon-placeholder" />
                        <h3>Experienced Team</h3>
                        <p>Our team of experts brings years of experience to the table.</p>
                    </div>
                    <div className="feature-item">
                        <i className="icon-placeholder" />
                        <h3>Modern Tools</h3>
                        <p>We utilize the latest tools and technologies for optimal results.</p>
                    </div>
                    <div className="feature-item">
                        <i className="icon-placeholder" />
                        <h3>24/7 Support</h3>
                        <p>Our support team is available around the clock to assist you.</p>
                    </div>
                </div>
                </div>
            </section>


        </div>
    );
};

export default AgencyPage;
