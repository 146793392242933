// Aboutservices.js
import React from 'react';
import './Aboutservices.css';
import { IoMdMail } from "react-icons/io";
import { Link } from 'react-router-dom';

const Aboutservices = () => {
    return (
        <section className="services">
            <div className="services-content">
                <h2>Professional Digital Services and Solutions</h2>
                <p>
                    Fusce non congue velit, vitae congue massa. Morbi pellentesque sapien sit amet egestas efficitur.
                    Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam nunc sem, sodales velic volutpat
                    non, finibus ligula. Nulla facilisi.
                </p>
                <Link to='/contact'>
                    <button className="cta-button">Get Started</button>
                </Link>
            </div>
            <div className="services-grid">
                <div className="services-card">
                    <IoMdMail />
                    <h2>Web Development</h2>
                    <p>
                        We design and build responsive, user-friendly websites that not only look great but also enhance
                        user engagement. Our web solutions are tailored to drive business growth and deliver a seamless
                        digital experience.
                    </p>
                </div>
                <div className="services-card">
                    <IoMdMail />
                    <h2>Graphic Design</h2>
                    <p>
                        We create visually compelling designs that capture your brand’s essence and communicate your
                        message effectively. Our graphics are tailored to enhance brand identity and leave a lasting
                        impression on your audience.
                    </p>
                </div>
                <div className="services-card">
                    <IoMdMail />
                    <h2>Digital Marketing</h2>
                    <p>
                        We develop targeted digital marketing strategies that boost your online presence and drive
                        measurable results. From SEO to social media, our campaigns are designed to engage your audience
                        and accelerate business growth.
                    </p>
                </div>
                <div className="services-card">
                    <IoMdMail />
                    <h2>SEO Services</h2>
                    <p>
                        We optimize your website to rank higher on search engines, driving organic traffic and increasing
                        visibility. Our tailored SEO strategies ensure long-term success and better search engine
                        performance.
                    </p>
                </div>
                <div className="services-card">
                    <IoMdMail />
                    <h2>Branding</h2>
                    <p>
                        Our branding services help you craft a distinct identity that resonates with your audience. We
                        ensure your brand stands out, builds trust, and leaves a lasting impression.
                    </p>
                </div>
                <div className="services-card">
                    <IoMdMail />
                    <h2>Sales Funnel</h2>
                    <p>
                        A sales funnel is a step-by-step process that guides potential customers from awareness to
                        purchase. It’s designed to nurture leads, address their needs, and convert them into loyal
                        customers.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Aboutservices;
