import React from "react";
import "./Blog.css";
import Navbar from './../components/Layout/Navbar'
import Footer from './Layout/Footer'

const Blog = () => {
  const posts = [
    {
      image: "path-to-image-1.jpg", // Replace with the actual image path
      category: "Uncategorized",
      title: "10 Proven Strategies to Accelerate Business Growth in 2024",
      description:
        "High quality digital services. Next-generation digital services for business growth.",
      authorEmail: "banerjeeashu@gmail.com",
      date: "14/08/2024",
    },
    {
      image: "path-to-image-2.jpg", // Replace with the actual image path
      category: "Business",
      title: "How to Improve Your Business with Digital Marketing",
      description:
        "Learn the best practices in digital marketing to take your business to the next level.",
      authorEmail: "john.doe@gmail.com",
      date: "20/08/2024",
    },
    {
      image: "path-to-image-3.jpg", // Replace with the actual image path
      category: "Marketing",
      title: "The Importance of SEO in 2024",
      description:
        "SEO continues to be a critical component of online success. Discover the latest trends.",
      authorEmail: "jane.doe@gmail.com",
      date: "25/08/2024",
    },
  ];

  return (
    <>

    <div className="blog-container">
    <Navbar/>
      <h2 className="blog-title">Read the latest posts from our blog</h2>
      <div className="blog-cards">
        {posts.map((post, index) => (
          <div className="blog-card" key={index}>
            <img src={post.image} alt={post.title} className="blog-image" />
            <div className="blog-details">
              <p className="blog-category">{post.category}</p>
              <h3 className="blog-post-title">{post.title}</h3>
              <p className="blog-description">{post.description}</p>
              <p className="blog-author-date">
                {post.authorEmail} • {post.date}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Footer/>
    </div>

    </>
  );
};

export default Blog;
