import React, { useEffect } from 'react';
import Navbar from '../Layout/Navbar';
import Aboutcontainer from '../Aboutcontainer';
import Aboutservices from '../Aboutservices';
import DigitalBusiness from '../DigitalBusiness';
import Footer from '../Layout/Footer';
import Aboutdigitalservices from '../Aboutdigitalservices';

const About = () => {
    useEffect(() => {
        // Scrolls to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <Aboutcontainer/>
            <Aboutdigitalservices/>
            <Aboutservices/>
            <DigitalBusiness/>
        { /*   <CallToAction/>*/}
            <Footer/>
        </>
    );
}

export default About;
