import React from 'react';
import './Mao.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Map = () => {
  return (
    <div className="map-container">
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.2889612081397!2d-0.12022318422937316!3d51.50347297963491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604c7c7eb9be3%3A0x3918653583725b56!2sLondon%20Eye!5e0!3m2!1sen!2suk!4v1628711478809!5m2!1sen!2suk"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="contact-info">
        <div className="contact-item">
          <div className="icon-container">
            <FaMapMarkerAlt className="icon" />
          </div>
          <h3>Our address</h3>
          <p>Example address text</p>
        </div>
        <div className="contact-item">
          <div className="icon-container">
            <FaPhone className="icon" />
          </div>
          <h3>Our phone</h3>
          <p>Mobile : +012 345 56 78 90</p>
          <p>Fax : 001234 55 00 99 11</p>
        </div>
        <div className="contact-item">
          <div className="icon-container">
            <FaEnvelope className="icon" />
          </div>
          <h3>Our mail</h3>
          <p>leadsgorilla@example.com</p>
        </div>
      </div>
    </div>
  );
};

export default Map;
