import React from 'react';
import './Aboutdigitalservices.css';
import { FaCheckCircle } from 'react-icons/fa';
import image from '../assets/cpt-inner-img-about.webp';
import logo from '../assets/co-sponsor-01-dark.webp';

const Aboutdigitalservices = () => {
    return (
        <section className="digital-services">
            <div className="services1-grid">
                <div className="image-grid">
                    <img src={image} alt="Service 1" />
                </div>
                <div className="services-content">
                    <h4>WHAT WE DO</h4>
                    <h2>We offer you modern digital services</h2>
                    <p>
                        Fusce non congue velit, vitae congue massa. Morbi pellentesque sapien sit amet egestas efficitur.
                        Interdum et malesuada fames ante ipsum primis in faucibus. Nam nunc sem, sodales velic volutpat non,
                        finibus ligula. Nulla facilisi.
                    </p>
                    <ul className="services-list">
                        <li><FaCheckCircle className="check-icon" /> Software development services</li>
                        <li><FaCheckCircle className="check-icon" /> Search engine optimization</li>
                        <li><FaCheckCircle className="check-icon" /> Digital marketing services</li>
                        <li><FaCheckCircle className="check-icon" /> Web & Product Design services</li>
                        <li><FaCheckCircle className="check-icon" /> E-mail & Content marketing</li>
                    </ul>
                </div>
            </div>
            <div className="clients">

            </div>
        </section>
    );
};

export default Aboutdigitalservices;
