import React from 'react';
import './Topcontainer.css';
import banner from '../assets/cpm-banner-img.webp';
import { Link } from 'react-router-dom';


const Topcontainer = () => {
    return (
        <section className="topcontainer-main">
            <div className="topcontainer-container">
                <div className="topcontainer-content">
                    <div className="content-left">
                        <div className="content-top"></div>
                        <div className="content-middle">
                            <h1>Next-generation digital services</h1>
                            <p>G For Growth is your go-to resource for actionable strategies and insights designed to accelerate business success. Whether you’re a startup founder or a seasoned entrepreneur, G For Growth provides expert advice on digital marketing, business development, and growth hacking. Our mission is to empower businesses to thrive in today’s competitive landscape through proven techniques and innovative solutions.</p>
                        </div>
                        <div className="content-bottom">
                           <Link to='/contact'> <button>Get Started</button></Link>
                     
                        </div>
                    </div>
                    <div className="content-right">
                        <img src={banner} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Topcontainer;
