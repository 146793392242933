import React from 'react';
import './CallToAction.css';

function CallToAction() {
    return (
        <div className="cta-section">
            <p className="cta-intro">Let's Start</p>
            <h2 className="cta-heading">Ready to start your digital business now?</h2>
            <button className="cta-button">Get started</button>
        </div>
    );
}

export default CallToAction;