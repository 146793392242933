import React from 'react';
import './AgencySection.css'; // Create a CSS file for styles
import agency from '../assets/sec-img-cmp.webp'
import person from "../assets/cpt-testimonial-3.webp"
const AgencySection = () => {
    return (
        <section className="agency-section">
            <div className="agency-container">
                <div className="agency-image">
                    <img src={agency} alt="Agency Team" />
                </div>
                <div className="agency-content">
                    <h4>About Our Agency</h4>
                    <h1>We are working for your success</h1>
                    <p>
                        G For Growth is a dynamic agency dedicated to driving business success through tailored marketing strategies and innovative solutions. We specialize in branding, sales funnels, and growth tactics that propel your business forward. With a focus on measurable results and long-term impact, our team partners with you to unlock your business’s full potential. At G For Growth, we don’t just help you grow—we help you thrive.
                    </p>
                    <div className="agency-author">
                        <img src={person} alt="Author" />
                        <div>
                            <p>Sanjay Banerjee</p>
                            <span>The Boss</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AgencySection;
