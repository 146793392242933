import React, { useEffect } from 'react';
import Navbar from '../Layout/Navbar';
import Topcontainer from '../Topcontainer';
import Services from '../Services';
import AgencySection from '../AgencySection';
import AgencyPage from '../AgencyPage';
import DigitalBusiness from '../DigitalBusiness';
import TeamSection from '../TeamSection';
import Footer from '../Layout/Footer';
import Marque from './Marque';

const Home = () => {
    useEffect(() => {
        // Scrolls to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* <Header /> */}
            <Navbar/>
            <Topcontainer/>
            {/* <Logos/> */}
            <Services/>
            <AgencySection/>
            <AgencyPage/>
            {/* <ImageHoverText/> */}
            <DigitalBusiness/>
            <Marque/>
            <TeamSection/>
   {       /*  <Test/>*/}
            {/* <Blog/> */}
       {    /* <CallToAction/>*/}
            <Footer/>
        </>
    )
}

export default Home;
