import React from 'react';
import './TeamSection.css';
import member1 from '../assets/cpt-testimonial-3.webp'; // replace with your actual image paths
import member2 from '../assets/cpt-testimonial-3.webp';
import member3 from '../assets/cpt-testimonial-3.webp';
import member4 from '../assets/cpt-testimonial-3.webp';

const TeamSection = () => {
   
    const stats = [
        { number: '280+', label: 'Customers' },
        { number: '650+', label: 'Completed Projects' },
        { number: '50+', label: 'Team Members' },
        
    ];

    return (
        <div className="team-section">
            <div className="team-header">
        
            </div>
         
            <div className="team-stats">
                {stats.map((stat, index) => (
                    <div className="stat" key={index}>
                        <h2 className="stat-number">{stat.number}</h2>
                        <p className="stat-label">{stat.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamSection;
