import React from 'react';
import './Aboutconatiner.css';
import pic1 from './../assets/pic1.jpg'
import pic2 from './../assets/pic2.jpg'

const Aboutcontainer = () => {
  return (
    <div className='aboutcontainer-main'>
      <h2>About Us</h2>
      <p>We are a professional digital agency with more than 10 years of experience and hundreds of satisfied customers.</p>
      
      <div className='aboutcontainer-images'>
        <div className='aboutcontainer-image-item'>
          <img src={pic1} alt='Description of image 1' />
          <h3>Ashutosh About:</h3>
          <p>Ashutosh Banerjee is a seasoned marketing strategist and creative consultant with extensive
          experience in the hospitality and D2C. He is recognized for his expertise in crafting
          innovative marketing strategies that drive brand growth and engagement.
          Ashutosh specializes in social media strategy, trend forecasting, branding, paid advertising,
          and email automation. His comprehensive approach also includes website development,
          SEO optimization, and mobile application development, ensuring that each client’s unique
          needs are met with tailored solutions.
          Ashutosh's work is marked by a blend of creativity and practicality, allowing brands to
          connect with their audiences effectively while staying ahead of industry trends. Outside of his
          professional pursuits, Ashutosh brings a personal touch to his work, drawing inspiration from
          his love for travel, music, and his Labrador, which adds a relatable and warm dimension to
          his client relationships and creative projects</p>
        </div>
        <div className='aboutcontainer-image-item'>
          <img src={pic2} alt='Description of image 2' />
          <h3>Sanjay Banerjee About:
          </h3>
          <p>Sanjay Banerjee is the driving force behind GforGrowth, a digital marketing agency he
          established 16 years ago. His mission is to empower businesses to reach their full potential
          by leveraging innovative strategies and cutting-edge technology. Sanjay’s expertise extends
          beyond traditional marketing—he is also a recognized specialist in growth hacking, a
          methodology that uses creative, low-cost strategies to help businesses grow quickly.
          Throughout his extensive career, Sanjay has successfully implemented growth hacking
          techniques to drive rapid business expansion for his clients. His unique combination of
          traditional marketing experience and growth hacking expertise allows him to create highly
          effective campaigns that deliver tangible results.</p>
        </div>
      </div>
    </div>
  );
}

export default Aboutcontainer;
